import { graphql } from 'gatsby';
import React from 'react';
import data from '../../../form-data/index';
import PaymentsData from '../../../site-data/sell/payments';
import PerksData from '../../../site-data/sell/perks';
import Layout from '../../components/Layout';
import MainFooter from '../../components/MainFooter';
import ApplyJumbotron from '../../components/Sell/ApplyJumbotron';
import Flexibility from '../../components/Sell/Flexibility';
import OnBoarding from '../../components/Sell/OnBoarding';
import Payments from '../../components/Sell/Payments';
import Perks from '../../components/Sell/Perks';
import SellHeader from '../../components/Sell/SellHeader';
import SellTestimonial from '../../components/Sell/SellTestimonial';
import SEO from '../../components/SEO';

const Sell = props => {
	const { onBoarding, flexibility, testimonial } = props.data;
	return (
		<Layout {...props} hasFooter={false} banner={data.banner} fullWidth>
			<SEO
				title="How to Sell on HostJane - Become a HostJane Seller"
				description="Open a studio today and earn money doing virtual work or teaching lessons to anyone online."
			/>
			<SellHeader />
			<OnBoarding
				heading={onBoarding.frontmatter.heading}
				image={onBoarding.frontmatter.image.childImageSharp.fluid}
				btnText={onBoarding.frontmatter.btnText}
				btnLink={onBoarding.frontmatter.btnLink}
				label={onBoarding.frontmatter.label}
			/>
			<Flexibility
				heading={flexibility.frontmatter.heading}
				description={flexibility.frontmatter.description}
			/>
			<Payments
				heading={PaymentsData.heading}
				paymentPerks={PaymentsData.paymentPerks}
			/>
			<Perks
				leftSectionHeading={PerksData.leftSectionHeading}
				leftSectionBulletsData={PerksData.leftSectionBulletsData}
				rightSectionHeading={PerksData.rightSectionHeading}
				rightSectionBulletsData={PerksData.rightSectionBulletsData}
			/>
			<SellTestimonial
				review={testimonial.frontmatter.review}
				client={testimonial.frontmatter.client}
				image={testimonial.frontmatter.image.childImageSharp.fluid}
			/>
			<ApplyJumbotron />
			<MainFooter />
		</Layout>
	);
};

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
				nav {
					path
					icon
					title
					desc
				}
			}
		}
		onBoarding: mdx(
			fileAbsolutePath: {
				glob: "**/site-data/sell/on-boarding/content.mdx"
			}
		) {
			body
			rawBody
			frontmatter {
				heading
				label
				btnText
				btnLink
				image {
					childImageSharp {
						fluid(maxWidth: 1200) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
		}
		flexibility: mdx(
			fileAbsolutePath: {
				glob: "**/site-data/sell/flexibility/content.mdx"
			}
		) {
			body
			rawBody
			frontmatter {
				heading
				description
			}
		}
		testimonial: mdx(
			fileAbsolutePath: {
				glob: "**/site-data/sell/sell-testimonials/content.mdx"
			}
		) {
			body
			rawBody
			frontmatter {
				review
				client
				image {
					childImageSharp {
						fluid(maxWidth: 1200) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
		}
	}
`;

export default Sell;
