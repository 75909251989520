import React from 'react';
import styled from 'styled-components';

const PaymentsWrapper = styled.section`
	display: flex;
	flex-direction: column;
	background-color: #f2f1f1;

	@media only screen and (min-width: 900px) {
		padding: 96px 0;
	}
	.hostjane-main__payments__body {
		width: 100%;
		padding-top: 24px;
		margin-left: auto;
		margin-right: auto;
		@media only screen and (min-width: 901px) {
			max-width: 900px;
			width: 100%;
		}
		h2 {
			font-size: 28px;
			font-weight: 900;
			margin: 0px 16px;
			@media only screen and (min-width: 900px) {
				font-size: 40px;
				margin-left: 0;
				margin-right: 0;
			}
		}
		.hostjane-main__payments__body__inline-content {
			margin-top: 16px;
			@media only screen and (min-width: 900px) {
				display: table;
				margin-top: 40px;
				width: 100%;
			}
			.hostjane-main__payments__body__inline-content__body {
				@media only screen and (min-width: 900px) {
					width: 344px;
					padding-right: 40px;
					display: table-cell;
				}
				.hostjane-main__payments__body__inline-content__body__content {
					display: flex;
					padding: 16px;
					margin-top: 16px;
					margin-left: 16px;
					margin-right: 16px;
					background-color: #fff;
					@media only screen and (min-width: 900px) {
						display: flex;
						padding: 16px;
						margin-top: 16px;
						margin-left: 0px;
						margin-right: 0px;
						background-color: #fff;
						&:first-child {
							margin-top: 0;
						}
					}
					img {
						margin-right: 16px;
					}
					div {
						h3 {
							font-size: 18px;
							font-weight: 900;
							color: #222222;
							margin: 0;
							line-height: initial;
						}
						p {
							font-size: 18px;
							margin: 0;
							color: #2e3333;
							line-height: 1.6em;
						}
					}

					svg {
						width: 40px;
						height: 47px;
						margin-right: 16px;
					}
				}
			}
			.hostjane--main__payments__body__inline-content__rider-image {
				margin-top: 24px;
				flex: auto;
				width: 100%;
				height: 240px;
				background-image: url('https://www.hostjane.com/assets/earn-on-fly.jpg');
				position: relative;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;

				@media only screen and (min-width: 900px) {
					display: table-cell;
					width: 384px;
					box-shadow: 10px 10px 50px 0px rgba(0, 0, 0, 0.2);
					height: auto;
				}
			}
		}
	}
`;

const Payments = ({ heading, paymentPerks }) => {
	return (
		<PaymentsWrapper className="hostjane-main__payments">
			<div className="hostjane-main__payments__body">
				<h2>{heading}</h2>
				<div className="hostjane-main__payments__body__inline-content">
					<div className="hostjane-main__payments__body__inline-content__body">
						{paymentPerks.map(({ title, description, icon }) => {
							return (
								<div className="hostjane-main__payments__body__inline-content__body__content">
									{icon}
									<div>
										<h3>{title}</h3>
										<p>{description}</p>
									</div>
								</div>
							);
						})}
					</div>
					<div className="hostjane--main__payments__body__inline-content__rider-image" />
				</div>
			</div>
		</PaymentsWrapper>
	);
};

export default Payments;
