import React from 'react';
import { ReactComponent as Free } from './join-free.svg';
import { ReactComponent as Income } from './income.svg';
import { ReactComponent as Payments } from './payments.svg';

const PaymentsData = {
	heading: 'FAQs',
	paymentPerks: [
		{
			title: 'Do Sellers pay any fees?',
			description: 'None. It\'s free to sell. $0.00 in costs.',
			icon: <Free />,
		},
		{
			title: 'How much can I earn?',
			description: '95% of sales. 100% of donations.',
			icon: <Income />,
		},
		{
			title: 'What payout methods?',
			description: 'VISA® card, PayPal/Venmo, or bank.',
			icon: <Payments />,
		},
	],
};

export default PaymentsData;
