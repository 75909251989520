import React from 'react';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';
import { ReactComponent as WavingHand } from '../../../svgs/icons/waving-hand.svg';

const TestimonialWrapper = styled.section`
	display: flex;
	flex-direction: column;
	background-color: #000000;
	@media only screen and (min-width: 900px) {
		flex-direction: row;
		height: 360px;
	}

	.hostjane-main-Testimonial__content {
		flex: auto;
		padding: 64px;
		@media only screen and (max-width: 900px) {
			padding: 16px;
			padding-bottom: 40px;
		}
		blockquote {
			display: flex;
			font-size: 50px;
			margin: 0;
			color: #fff;
			line-height: 1.3;
			quotes: none;
			@media only screen and (max-width: 1192px) {
				font-size: 35px;
			}
			@media only screen and (max-width: 992px) {
				font-size: 30px;
			}
			p {
				margin: 0;
				margin-right: 8px;
			}
		}
		footer {
			color: white;
			display: block;
			margin-top: 20px;
			font-weight: bold;
			font-size: 25px;
			display: flex;
			align-items: center;
			svg {
				width: 30px;
				height: 30px;
				margin-left: 5px;
			}
		}
	}
`;

const ClientImageWrapper = styled.div`
	flex: 0 0 45%;
	height: 100%;
	background-image: url(${props => props.clientImage.src});
	position: relative;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	@media only screen and (max-width: 900px) {
		height: 331px;
		flex: auto;
	}
	.hostjane-main-Testimonial__image-slant {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(3deg, #000000 39px, transparent 40px);
		@media only screen and (min-width: 900px) {
			height: 100%;
			background: linear-gradient(-93deg, #000000 30px, transparent 31px);
		}
	}
`;

const SellTestimonial = ({ review, client, image }) => {
	return (
		<TestimonialWrapper className="hostjane-main-Testimonial">
			<ClientImageWrapper
				className="hostjane-main-Testimonial__client"
				clientImage={image}
			>
				<div className="hostjane-main-Testimonial__image-slant" />
			</ClientImageWrapper>
			<div className="hostjane-main-Testimonial__content">
				<blockquote>
					<p>Work from</p>
					<Typewriter
						options={{
							strings: [...review.split(',')],
							loop: true,
							delay: 15,
							autoStart: true,
							deleteSpeed: 15,
						}}
					/>
				</blockquote>
				<footer>
					{client} <WavingHand />
				</footer>
			</div>
		</TestimonialWrapper>
	);
};

export default SellTestimonial;
