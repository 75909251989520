import React from 'react';
import styled from 'styled-components';

const ApplyJumbotronWrapper = styled.section`
	text-align: center;
	padding-top: 40px;
	padding-bottom: 40px;
	background-color: #fcfcfc;
	padding: 16px;
	padding-top: 80px;

	@media screen and (min-width: 900px) {
		padding-bottom: 80px;
	}

	h2 {
		margin: 0;
		lien-height: initial;
		font-size: 28px;
		font-weight: 900;
	}
	p {
		color: #2e3333;
		font-size: 18px;
		margin: 0;
		margin-top: 16px;
		line-height: 1.5em;
	}
	a {
		display: inline-block;
		width: 100%;
		height: 56px;
		line-height: 56px;
		font-size: 18px;
		font-weight: 900;
		border-radius: 2px;
		text-align: center;
		text-decoration: none;
		background-color: #00ccbc;
		&:hover {
			background-color: #14b5a8;
			text-decoration: none;
		}
		color: #fff !important;
		box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
		transition: background 0.3s, color 0.3s;
		margin-top: 16px;
		width: 136px;
		@media only screen and (min-width: 900px) {
			width: 376px;
		}
	}
`;

const ApplyJumbotron = () => {
	return (
		<ApplyJumbotronWrapper>
			<h2>Sign up, it's free</h2>
			<p>You can save and edit later.</p>
			<a href="/marketplace/prelist">Continue</a>
		</ApplyJumbotronWrapper>
	);
};

export default ApplyJumbotron;
