import React from 'react';
import styled from 'styled-components';
import { HostJaneLogoLink } from '../../MainNavigation/common';

const SellHeaderWrapper = styled.div`
  height: 60px;
  font-size: 18px;
  height: 50px;
  background: #fff;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
  display: block;
  height: 80px;
  width: calc(100% - 110px);
  position: fixed;
  z-index: 121;
  padding: 0px 15px;
  top: 0;

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  @media only screen and (max-width: 991px) and (min-width: 0px) {
    height: 50px;
  }
  @media only screen and (max-width: 767px) and (min-width: 0px) {
    width: 100%;
  }

  .hostjane-main-sell-header__layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    padding: 0 16px;

    @media only screen and (min-width: 992px) {
      width: 900px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      margin: 0 auto;
      padding: 0 16px;
    }
    @media only screen and (min-width: 991px) and (max-width: 1020px) {
      width: 800px;
    }
    // @media only screen and (max-width: 991px) {
    //   width: 850px;
    // }

    .hostjane-main-sell-header__layout__button {
      a {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        padding: 0 8px;
        text-decoration: none;
        color: #0073bb;

        @media only screen and (min-width: 992px) {
          display: inline-block;
          width: 100%;
          height: 56px;
          line-height: 56px;
          font-size: 18px;
          font-weight: 900;
          border-radius: 2px;
          text-align: center;
          background-color: #00ccbc;
          &:hover {
            background-color: #14b5a8;
          }
          color: #fff;
          box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
          transition: background 0.3s, color 0.3s;
          width: 135px;
          height: 48px;
          line-height: 48px;
        }
      }
    }

    .hostjane-main-sell-header__layout__logo {
      img {
        height: 43px;
        width: 40px;
      }
    }
  }
`;
const SellHeader = () => {
	return (
		<SellHeaderWrapper className="hostjane-main-sell-header">
			<div className="hostjane-main-sell-header__layout">
				<div className="hostjane-main-sell-header__layout__logo">
					<HostJaneLogoLink
						className="hostjane-desktop-nav__logo"
						to="/"
						width={225}
					/>
				</div>
				<div className="hostjane-main-sell-header__layout__button">
					<a href="/marketplace/prelist">Continue</a>
				</div>
			</div>
		</SellHeaderWrapper>
	);
};

export default SellHeader;
