import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const OnBoardingWrapper = styled.section`
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	background-color: #000000;

	@media only screen and (min-width: 992px) {
		margin-top: 60px;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		height: 456px;
	}
	.hostjane-main__onboarding__cta-body {
		flex: auto;
		order: 1;
		padding: 16px;
		padding-top: 0;

		h1,
		p {
			color: #fff;
		}
		h1 {
			font-size: 35px;
			font-weight: 900;
			line-height: initial;
			margin: 0;
		}
		p {
			margin: 0;
			margin-top: 16px;
			font-size: 20px;
			line-height: 1.5em;
		}

		a {
			text-decoration: none;
			display: inline-block;
			width: 100%;
			height: 56px;
			line-height: 56px;
			font-size: 18px;
			font-weight: bold;
			border-radius: 2px;
			text-align: center;
			background-color: #fff;
			color: #00;
			box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
			transition: background 0.3s, color 0.3s;
			margin-top: 16px;
			cursor: pointer;
			&:hover {
				color: #0a2540;
			}
			&:active {
				color: #9966ff;
			}
			&:focus {
				color: #0a2540;
			}
			@media only screen and (min-width: 992px) {
				width: 132px;
			}
		}

		@media only screen and (min-width: 992px) {
			flex: none;
			order: 0;
			width: 324px;
			padding: 0;
			margin-right: 60px;
			h1 {
				font-size: 40px;
				text-transform: none;
			}
			p {
				font-size: 20px;
			}
			button {
				width: 132px;
			}
		}
	}
`;

const RiderImage = styled.div`
	background-image: url(${props => props.imageSrc});
	background-position: top;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	flex: none;
	order: 1;
	width: 50%;
	height: 100%;
	@media screen and (max-width: 992px) {
		height: 194px;
		width: 100%;
	}
	.hostjane-main__onboarding__rider-image-slant {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(3deg, #E41937 42px, transparent 43px);
		@media only screen and (min-width: 992px) {
			background: linear-gradient(87deg, #E41937 30px, transparent 31px);
		}
	}
`;

const OnBoarding = ({ heading, image, btnText, btnLink, label }) => {
	return (
		<OnBoardingWrapper className="hostjane-main__onboarding">
			<RiderImage
				imageSrc={image.src}
				className="hostjane-main__onboarding__rider-image"
			>
				<div className="hostjane-main__onboarding__rider-image-slant" />
			</RiderImage>
			<div className="hostjane-main__onboarding__cta-body">
				<h1>{heading}</h1>
				<p>{label}</p>
				<a href={btnLink}>
					{btnText}
				</a>
			</div>
		</OnBoardingWrapper>
	);
};

export default OnBoarding;
