import React from 'react';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as Kanban } from './kanban.svg';
import { ReactComponent as BrainPower } from './brain-power.svg';
import { ReactComponent as PaidOnTime } from './paid-on-time.svg';

const PerksData = {
	leftSectionHeading: 'How do I start?',
	leftSectionBulletsData: [
		{
			icon: <BrainPower />,
			bullet:
				'It takes 30 seconds to start a draft. Join free and finish it later.',
			linkText: 'Start your free listing >',
			link: 'https://www.hostjane.com/marketplace/prelist',
		},
		{
			icon: <Kanban />,
			bullet:
				'Deliver top work with chat and smart work boards.',
			linkText: 'A better workspace >',
			link: 'https://help.hostjane.com/buyers/hostjane-workspace/',
		},
		{
			icon: <PaidOnTime />,
			bullet:
				'Get paid on time. We pay all overheads incl. processing fees.',
			linkText: 'Payment FAQ >',
			link: 'https://help.hostjane.com/sellers/payments-tax-credits/',
		},
	],
	rightSectionHeading: "Things you'll need",
	rightSectionBulletsData: [
		{
			icon: <Check />,
			bullet:
				'A Skill — something you can do that most people have to pay for',
		},
		{
			icon: <Check />,
			bullet:
				'An email — use a phone app like gmail so you know about new orders and messages',
		},
		{
			icon: <Check />,
			bullet:
				'Any VISA® card, PayPal/Venmo, or bank account — funds instantly credited to your VISA® card, VISA® virtual card, PayPal, Venmo, or free to any bank in over 100 countries',
		},
	],
};

export default PerksData;
